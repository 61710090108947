import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const Heading = makeShortcode("Heading");
const ResourceCardGrid = makeShortcode("ResourceCardGrid");
const ResourceCard = makeShortcode("ResourceCard");
const VercelAnalytics = makeShortcode("VercelAnalytics");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ArticlePageWrapper mdxType="ArticlePageWrapper">
  <Heading variant="h700" mdxType="Heading">Content</Heading>
  <ResourceCardGrid mdxType="ResourceCardGrid">
    <ResourceCard title={`Foundations`} subtitle={`Learn about Appfire's voice and tone and how to prioritize accessibility.`} href={`/content/foundations`} actionIcon={`link`} docsTheme={`brand`} mdxType="ResourceCard" />
    <ResourceCard title={`Grammar and mechanics`} subtitle={`Overcome common writing challenges and stay consistent.`} href={`/content/grammar-and-mechanics`} actionIcon={`link`} docsTheme={`brand`} mdxType="ResourceCard" />
    <ResourceCard title={`Error messages`} subtitle={`Guide users through disruptions with clear, actionable wording.`} href={`/content/error-messages`} actionIcon={`link`} docsTheme={`brand`} mdxType="ResourceCard" />
   
  </ResourceCardGrid>
    </ArticlePageWrapper>
    <VercelAnalytics mdxType="VercelAnalytics" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      